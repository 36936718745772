<script setup>
const visible = ref(false);
const collapseProduct = ref(false);
const domain = "https://datanomic.qrs.vn";
const route = useRoute();

useHead({
  link: [{ rel: "canonical", href: domain + route.path }],
  meta: [
    { name: "og:url", content: domain + route.path },
    { name: "og:type", content: "website" },
    { name: "og:image", content: domain + "/images/qrx.jpg" },
    { name: "twitter:card", content: "summary" },
    { name: "twitter:image", content: domain + "/images/qrx.jpg" },
  ],
});
const offerings = ["/data-strategy", "/data-management", "/data-governance", "/data-analytics", "/cdp"];
const isOurService = computed(() => route.path === "/our-service");
const isOfferings = computed(() => offerings.includes(route.path));
const isAboutUs = computed(() => route.path === "/about-us");
const isContactUs = computed(() => route.path === "/contact-us");

watch(
  route,
  () => {
    if (visible.value) {
      visible.value = false;
    }
  },
  { deep: true },
);
const isScrolled = ref(false);

const handleScroll = () => {
  isScrolled.value = window.scrollY > 10;
};

onMounted(() => {
  Array.from(document.querySelectorAll("a[href^='http://localhost']")).forEach((node) => {
    node.href = node.href.replace("http://localhost", window.location.origin);
  });

  window.addEventListener("scroll", handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<template>
  <div class="min-h-[100vh] header-tranparent" :class="{ scroll: isScrolled }">
    <header class="w-full shadow-lg bg-[#00469D]">
      <div class="w-[full] max-md:w-[122px] pl-[80px] max-md:pl-0">
        <nuxt-link to="/">
          <nuxt-picture loading="lazy" src="/images/datanomic/logo/logo-1.png" :img-attrs="{ alt: 'logo' }" />
        </nuxt-link>
      </div>

      <div class="nav-menu flex gap-x-[59px] items-center subtitle-3 max-xl:hidden">
        <nuxt-link to="/our-service" class="hover:text-[#0066E6]"
          :class="isOurService && 'p-4 bg-white rounded-lg !text-[#0066E6]'">Our Service</nuxt-link>
        <div class="relative hover:text-[#0066E6]" :class="isOfferings && 'p-4 bg-white rounded-lg !text-[#0066E6]'">
          Offerings

          <div class="sub-menu hidden absolute top-[100%] left-0 pt-5 shadow-sm shadow-[#dfdfdf]">
            <nuxt-link to="/data-strategy" class="subtitle-3 sub-menu-item hover:text-[#0066E6]">DATA STRATEGY</nuxt-link>
            <nuxt-link to="/data-management" class="subtitle-3 sub-menu-item hover:text-[#0066E6]">DATA
              MANAGEMENT</nuxt-link>
            <nuxt-link to="/data-governance" class="subtitle-3 sub-menu-item hover:text-[#0066E6]">DATA
              GOVERNANCE</nuxt-link>
            <nuxt-link to="/data-analytics" class="subtitle-3 sub-menu-item hover:text-[#0066E6]">BI TOOL & DATA
              ANALYTICS</nuxt-link>
            <nuxt-link to="/cdp" class="subtitle-3 sub-menu-item hover:text-[#0066E6]">CDP</nuxt-link>
          </div>
        </div>
        <nuxt-link to="/about-us" class="hover:text-[#0066E6]"
          :class="isAboutUs && 'p-4 bg-white rounded-lg !text-[#0066E6]'">About us</nuxt-link>
        <nuxt-link to="/contact-us" class="hover:text-[#0066E6]"
          :class="isContactUs && 'p-4 bg-white rounded-lg !text-[#0066E6]'">Contact Us</nuxt-link>
      </div>

      <button class="small !px-2 border-2 border-white xl:hidden" @click="visible = true" aria-label="icon-3-dot">
        <svg viewBox="0 -53 384 384" width="20px" height="20px" class="fill-[#0066E6]">
          <path
            d="m368 154.667969h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
            stroke="#FFFFFF" fill="#FFFFFF" stroke-width="1" />
          <path
            d="m368 32h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
            stroke="#FFFFFF" fill="#FFFFFF" stroke-width="1" />
          <path
            d="m368 277.332031h-352c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h352c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0"
            stroke="#FFFFFF" fill="#FFFFFF" stroke-width="1" />
        </svg>
      </button>

      <a-drawer v-model:visible="visible" :bodyStyle="{ paddingTop: '45px' }" :contentWrapperStyle="{ width: '100vw' }"
        body :closable="false" placement="right">
        <template #title>
          <div class="flex items-center justify-between">
            <nuxt-link to="/"><nuxt-picture loading="lazy" src="/images/datanomic/logo/logo-1.png"
                :img-attrs="{ alt: 'logo' }" width="122" /></nuxt-link>

            <button class="small !px-2 bg-[#F0F0F0] border-none" @click="visible = false" aria-label="collapse-icon">
              <svg viewBox="0 0 329.26933 329" width="20px" height="20px" class="fill-[#0066E6]">
                <path
                  d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
              </svg>
            </button>
          </div>
        </template>

        <div class="py-3 px-2 hover:text-[#0066E6]">
          <nuxt-link to="/our-service" @click="visible = false">Our Service</nuxt-link>
        </div>

        <div class="py-3 px-2 border-solid border-b border-[#E0E0E0] flex items-center justify-between"
          @click="collapseProduct = !collapseProduct">
          <span>Offerings</span>

          <svg viewBox="0 0 24 24" width="20px" height="20px" class="fill-[#ed7f22] transition-all"
            :class="{ 'rotate-180 ': !collapseProduct }">
            <g id="_16" data-name="16">
              <path
                d="m12 16a1 1 0 0 1 -.71-.29l-6-6a1 1 0 0 1 1.42-1.42l5.29 5.3 5.29-5.29a1 1 0 0 1 1.41 1.41l-6 6a1 1 0 0 1 -.7.29z" />
            </g>
          </svg>
        </div>

        <div class="sub-menu flex flex-col gap-y-4 px-2 py-4 overflow-hidden transition-all"
          :class="{ 'h-0 !py-0': collapseProduct }">
          <nuxt-link to="/data-strategy" class="sub-menu-item subtitle-3 text-primary" @click="visible = false">
            DATA STRATEGY
          </nuxt-link>
          <nuxt-link to="/data-management" class="sub-menu-item subtitle-3 text-primary" @click="visible = false">
            DATA MANAGEMENT
          </nuxt-link>
          <nuxt-link to="/data-governance" class="sub-menu-item subtitle-3 text-primary" @click="visible = false">
            DATA GOVERNANCE
          </nuxt-link>
          <nuxt-link to="/data-analytics" class="sub-menu-item subtitle-3 text-primary" @click="visible = false">
            BI TOOL & DATA ANALYTICS
          </nuxt-link>
          <nuxt-link to="/cdp" class="sub-menu-item subtitle-3 text-primary" @click="visible = false">
            CDP
          </nuxt-link>
        </div>
        <div class="py-3 px-2">
          <nuxt-link to="/about-us" :external="true" class="hover:text-[#0066E6]">About us</nuxt-link>
        </div>
        <div class="py-3 px-2 hover:text-[#0066E6]">
          <nuxt-link to="/contact-us" @click="visible = false">Contact Us</nuxt-link>
        </div>
      </a-drawer>
    </header>

    <main class="page-content px-20 max-xl:px-4 py-10 max-xl:pt-0 mt-[80px] max-xl:mt-[64px] mb-[120px] max-md:mb-8">
      <NuxtPage />
    </main>

    <footer>                     
      <nuxt-picture loading="lazy" src="/images/datanomic/logo/Frame-892.png" :img-attrs="{ alt: 'logo' }" width="273" />

      <div class="flex max-lg:flex-wrap gap-[40px] mt-[40px]">
        <div id="company-info" class="max-lg:w-full">
          <div id="HN-address">
            <div class="flex items-center subtitle-2">
              <svg width="20" height="24" viewBox="0 0 17 17" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.26744 11.872C9.25635 11.3591 12.67 9.34622 12.67 6.00103C12.67 3.42295 10.5801 1.33301 8.002 1.33301C5.42393 1.33301 3.33398 3.42295 3.33398 6.00103C3.33398 9.34622 6.74766 11.3591 7.73657 11.872C7.90519 11.9595 8.09882 11.9595 8.26744 11.872ZM8.00204 8.00116C9.10693 8.00116 10.0026 7.10547 10.0026 6.00058C10.0026 4.89569 9.10693 4 8.00204 4C6.89715 4 6.00146 4.89569 6.00146 6.00058C6.00146 7.10547 6.89715 8.00116 8.00204 8.00116Z"
                  fill="#FCFCFD" />
              </svg>

              <div class="font-normal text-sm text-[#F4F5F6] ml-2 -mt-1">
                Hanoi office: 41, Phu Kieu 1, Kieu Mai, Bac Tu Liem, Ha Noi
              </div>
            </div>
          </div>

          <div id="HCM-address" class="mt-3">
            <div class="flex items-center subtitle-2">
              <svg width="20" height="24" viewBox="0 0 17 17" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.26744 11.872C9.25635 11.3591 12.67 9.34622 12.67 6.00103C12.67 3.42295 10.5801 1.33301 8.002 1.33301C5.42393 1.33301 3.33398 3.42295 3.33398 6.00103C3.33398 9.34622 6.74766 11.3591 7.73657 11.872C7.90519 11.9595 8.09882 11.9595 8.26744 11.872ZM8.00204 8.00116C9.10693 8.00116 10.0026 7.10547 10.0026 6.00058C10.0026 4.89569 9.10693 4 8.00204 4C6.89715 4 6.00146 4.89569 6.00146 6.00058C6.00146 7.10547 6.89715 8.00116 8.00204 8.00116Z"
                  fill="#FCFCFD" />
              </svg>

              <div class="font-normal text-sm text-[#F4F5F6] ml-2">HCM offcice: No 10, Pham Van Hai, Tan Binh, HCM</div>
            </div>
          </div>

          <div id="phone-number" class="flex items-center mt-4">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g clip-path="url(#clip0_180177_21763)">
                <path
                  d="M10.6599 0.666016H5.32982C4.22384 0.666016 3.33105 1.5588 3.33105 2.66478V13.3249C3.33105 14.4308 4.22384 15.3236 5.32982 15.3236H10.6599C11.7658 15.3236 12.6586 14.4308 12.6586 13.3249V2.66478C12.6586 1.5588 11.7658 0.666016 10.6599 0.666016ZM11.3261 11.9923H4.66356V2.66478H11.3261V11.9923ZM9.32735 13.9911H6.66233V13.3249H9.32735V13.9911Z"
                  fill="#FCFCFD" />
              </g>
              <defs>
                <clipPath id="clip0_180177_21763">
                  <rect width="15.9901" height="15.9901" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <div class="font-normal text-sm text-[#F4F5F6] ml-2">Tel: (+84)2432007319</div>
          </div>

          <div id="email" class="flex items-center max-md: mt-4">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M2.00685 6.80301C1.99805 7.16204 1.99805 7.56966 1.99805 8.03467V9.36686C1.99805 11.2509 1.99805 12.1929 2.58333 12.7782C3.16862 13.3634 4.11062 13.3634 5.99463 13.3634H9.99121C11.8752 13.3634 12.8172 13.3634 13.4025 12.7782C13.9878 12.1929 13.9878 11.2509 13.9878 9.36686V8.03467C13.9878 7.56966 13.9878 7.16204 13.979 6.80301L8.63989 9.76918C8.23754 9.99271 7.7483 9.99271 7.34595 9.76918L2.00685 6.80301ZM2.15995 5.38984C2.21513 5.4037 2.26943 5.42492 2.32153 5.45386L7.99292 8.60463L13.6643 5.45386C13.7164 5.42492 13.7707 5.40371 13.8259 5.38984C13.7393 5.07454 13.6064 4.8273 13.4025 4.62337C12.8172 4.03809 11.8752 4.03809 9.99121 4.03809H5.99463C4.11062 4.03809 3.16862 4.03809 2.58333 4.62337C2.3794 4.8273 2.24653 5.07454 2.15995 5.38984Z"
                fill="#FCFCFD" />
            </svg>

            <div class="font-normal text-sm text-[#F4F5F6] ml-2">Email: contact@datanomics.com.vn</div>
          </div>
        </div>

        <div id="product"
          class="flex max-md:flex-col max-md:mx-0 max-xl:mt-[20px] flex-col gap-y-6 max-lg:w-1/2 max-lg:flex-1 -mt-[55px] mx-[50px]">
          <nuxt-link to="/our-service" class="text-base font-bold text-[#FCFCFD]">Our Service</nuxt-link>
          <nuxt-link to="/about-us" class="text-base font-bold text-[#FCFCFD]">About us</nuxt-link>
          <nuxt-link to="/contact-us" class="text-base font-bold text-[#FCFCFD]">Contact us</nuxt-link>
        </div>

        <div id="QRX" class="flex flex-col gap-y-5 max-lg:w-1/2 max-lg:flex-1 -mt-[60px] max-xl:mt-[20px]">
          <div class="text-base font-bold text-[#FCFCFD]">Offerings</div>

          <nuxt-link to="/data-management" class="font-normal text-sm text-[#F4F5F6]">Data Management</nuxt-link>
          <nuxt-link href="/data-analytics" class="font-normal text-sm text-[#F4F5F6]">BI & Data Analytics</nuxt-link>
          <nuxt-link href="/data-strategy" class="font-normal text-sm text-[#F4F5F6]">Data Strategy</nuxt-link>
          <nuxt-link href="/data-governance" class="font-normal text-sm text-[#F4F5F6]">Data Governance</nuxt-link>
          <nuxt-link href="/cdp" class="font-normal text-sm text-[#F4F5F6]">Customer data platform</nuxt-link>
        </div>
      </div>

      <div class="flex justify-between items-center py-4 pt-[100px]">
        <small>© Copyright – 2023 | DATANOMICS | All Rights Reserved</small>
      </div>
    </footer>
  </div>
</template>
