import { default as about_45usRL7W09HS53Meta } from "/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/about-us.vue?macro=true";
import { default as cdpTvvkXBlhGpMeta } from "/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/cdp.vue?macro=true";
import { default as contact_45usvUxi0eA5K2Meta } from "/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/contact-us.vue?macro=true";
import { default as data_45analyticsWT5gpNufF2Meta } from "/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/data-analytics.vue?macro=true";
import { default as data_45governancejLos5aPfU5Meta } from "/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/data-governance.vue?macro=true";
import { default as data_45management8niTaExHJ1Meta } from "/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/data-management.vue?macro=true";
import { default as data_45strategyntGqcOTOfUMeta } from "/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/data-strategy.vue?macro=true";
import { default as indexsd31RpIMBgMeta } from "/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/index.vue?macro=true";
import { default as our_45serviceL7TaMdSBv4Meta } from "/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/our-service.vue?macro=true";
export default [
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/about-us.vue").then(m => m.default || m)
  },
  {
    name: "cdp",
    path: "/cdp",
    component: () => import("/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/cdp.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "data-analytics",
    path: "/data-analytics",
    component: () => import("/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/data-analytics.vue").then(m => m.default || m)
  },
  {
    name: "data-governance",
    path: "/data-governance",
    component: () => import("/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/data-governance.vue").then(m => m.default || m)
  },
  {
    name: "data-management",
    path: "/data-management",
    component: () => import("/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/data-management.vue").then(m => m.default || m)
  },
  {
    name: "data-strategy",
    path: "/data-strategy",
    component: () => import("/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/data-strategy.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "our-service",
    path: "/our-service",
    component: () => import("/home/runner/work/datanomic.qrs.vn/datanomic.qrs.vn/pages/our-service.vue").then(m => m.default || m)
  }
]